import React, { FC, useEffect } from 'react';
import {
  useEnvironment,
  useExperiments,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { EmptyState } from './EmptyState/EmptyState';
import { Header } from './Header/Header';
import { Body } from './Body/Body';
import { HiddenServicesForSeo } from './HiddenServicesForSeo/HiddenServicesForSeo';
import { WidgetViewModelProvider } from './hooks/useWidgetViewModel';
import { WidgetActionsProvider } from './hooks/useWidgetActions';
import { WidgetViewModel } from '../../../viewModel/viewModel';
import { WidgetActions } from '../../../actions/actions';
import { classes, st } from './Widget.st.css';
import { Dialog } from './Dialog/Dialog';
import { Widget as WidgetDeprecated } from '../Widget-deprecated/Widget';

export type ControllerProps = {
  widgetViewModel: WidgetViewModel;
  widgetActions: WidgetActions;
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { experiments } = useExperiments();

  if (experiments.enabled('specs.bookings.migrateServiceListToServiceV2')) {
    return props.widgetActions && props.widgetViewModel ? (
      <WidgetRenderer {...props} />
    ) : null;
  }

  return props.widgetActions && props.widgetViewModel ? (
    <WidgetDeprecated {...(props as any)} />
  ) : null;
};

const WidgetRenderer: FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel, widgetActions } = props;
  const { headerViewModel, services, seo } = widgetViewModel;
  const { isRTL, isMobile } = useEnvironment();

  useEffect(() => {
    props.widgetActions.getCourseAvailability();
  }, []);

  return (
    <div
      data-hook="widget"
      className={st(classes.root, {
        isRTL,
        isMobile,
      })}
    >
      <WidgetViewModelProvider value={widgetViewModel}>
        <WidgetActionsProvider value={widgetActions}>
          {services.length ? (
            <>
              <Dialog
                widgetViewModel={widgetViewModel}
                widgetActions={widgetActions}
              />

              {headerViewModel ? <Header /> : null}
              <Body widgetId={props.id} />
              {seo && seo.shouldListServicesFromOtherCategories ? (
                <HiddenServicesForSeo />
              ) : null}
            </>
          ) : (
            <EmptyState />
          )}
        </WidgetActionsProvider>
      </WidgetViewModelProvider>
    </div>
  );
};
