import { BusinessInfo } from '../../../legacy/types';
import { getDefaultTimezone } from '../timezone/timezone';
import { EnrichedService } from '../../types/types';
import { getPaymentDescription } from '@wix/bookings-calendar-catalog-viewer-mapper/domain';

export const getOfferedDays = (service: EnrichedService, t: Function) => {
  return 'implement offered days';
};

export const getFormattedStartDate = ({
  service,
  language,
  t,
  businessInfo,
}: {
  service: EnrichedService;
  language: string;
  t: Function;
  businessInfo: BusinessInfo;
}) => {
  if (service.schedule?.firstSessionStart) {
    try {
      const startDate = new Date(service.schedule?.firstSessionStart);

      const isDateInCurrentYear = (date: Date) => {
        const now = new Date();
        return now.getFullYear() === date.getFullYear();
      };

      const isDateInThePast = (date: Date) => {
        const now = new Date();
        return date.getTime() < now.getTime();
      };

      const timeZone = getDefaultTimezone(businessInfo);
      const dateFormat: Intl.DateTimeFormatOptions = isDateInCurrentYear(
        startDate,
      )
        ? {
            month: 'short',
            day: 'numeric',
            timeZone,
          }
        : {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone,
          };

      const formattedStartDate = new Intl.DateTimeFormat(
        language,
        dateFormat,
      ).format(startDate);

      return isDateInThePast(startDate)
        ? `${t(
            'service.schedule.course.start.date.passed',
          )} ${formattedStartDate}`
        : `${t(
            'service.schedule.course.start.date.future',
          )} ${formattedStartDate}`;
    } catch (e) {
      return '';
    }
  }
  return '';
};

export const getPrice = ({
  service,
  locale,
  t,
}: {
  service: EnrichedService;
  language: string;
  locale?: string;
  t: Function;
}): {
  displayedPrice: string;
  srOnlyPrice: string;
} => {
  const getPaymentDescriptionArgs: Parameters<typeof getPaymentDescription>[0] =
    {
      payment: service.payment!,
      regionalSettingsLocale: locale!,
      paymentFormatters: {
        variedPrice: ({ minPrice }) =>
          t('service.payment.from-price', { price: minPrice }),
        pricingPlans: () => '',
      },
    };

  return {
    displayedPrice: getPaymentDescription(getPaymentDescriptionArgs),
    srOnlyPrice: getPaymentDescription({
      ...getPaymentDescriptionArgs,
      currencyDisplay: 'name',
    }),
  };
};
